import React from 'react'
import { graphql } from 'gatsby'
import MainWrapper from '../containers/MainWrapper'
import SEO from '../components/SEO'
import Logo from '../components/Logo'
import YoutubeEmbed from '../components/YoutubeEmbed'
import Footer from '../components/Footer'
import Hero from '../containers/Hero'
import TourDates from '../containers/Tour'
import Header from '../components/Header'

const dates = [
  {
    date: "5 NOV.",
    location: {
      city: "Marseille",
      place: "Artplexe Canebière",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-artplexe-canebiere-18841979/"
    }
  },
  {
    date: "8 NOV.",
    location: {
      city: "Pully (ch)",
      place: "L'Octogone",
      order: "https://www.ticketcorner.ch/noapp/event/david-jarre-loctogone-de-pully-18855030/"
    }
  },
  {
    date: "15 NOV.",
    location: {
      city: "Lyon",
      place: "Salle Victor Hugo",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-salle-victor-hugo-lyon-18885546/"
    }
  },
  {
    date: "17 NOV.",
    location: {
      city: "Lille",
      place: "Théâtre Pasteur",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-grand-palais-lille-18914426/"
    }
  },
  {
    date: "1 DÉC.",
    location: {
      city: "Paris",
      place: "Le Trianon",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-le-trianon-18607046/"
    }
  }
];

const _dates = [
  {
    date: "21 JAN.",
    location: {
      city: "Bordeaux",
      place: "Théâtre Trianon",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-theatre-trianon-bordeaux-18729170/"
    }
  },
  {
    date: "22 JAN.",
    location: {
      city: "Toulouse",
      place: "La Comédie",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-la-comedie-de-toulouse-18729168/"
    }
  },
  {
    date: "8 MARS",
    location: {
      city: "Besançon",
      place: "Le petit Kursaal",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-kursaal-besancon-18867118/"
    }
  },
  {
    date: "11 MARS",
    location: {
      city: "Dijon",
      place: "Théâtre les feuillants",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-theatre-des-feuillants-dijon-18952276/"
    }
  },
  {
    date: "21 MARS",
    location: {
      city: "Seyssinet-Pariset",
      place: "L'Ilyade",
      order: "https://www.fnacspectacles.com/event/david-jarre-mosaic-ilyade-seyssinet-pariset-18892577/"
    }
  }
];

class Index extends React.Component {
  componentDidMount() {
    if (typeof document !== 'undefined' && typeof window !== 'undefined') {
      const heroContainer = document.getElementById('hero');
      const topContainer = document.querySelectorAll('.vcrop');
      const davidContainer = document.getElementById('david-jarre');
      const mosaicContainer = document.getElementById('mosaic');
      const captionContainer = document.getElementById('caption');

      window.addEventListener('scroll', () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const maxScroll = documentHeight - windowHeight;
        const scrollPercentage = Math.min(scrollPosition / maxScroll, 1);

        heroContainer.style.top = `-${(scrollPercentage*2000)}px`
        topContainer.forEach((item) => {
          item.style.height = `${(scrollPercentage * 4000)}px`
          item.style.top = `-${(scrollPercentage * 1500)}px`
        })
        davidContainer.style.marginTop = `${10 + (scrollPercentage * 1500)}px`
        mosaicContainer.style.marginTop = `${10 - (scrollPercentage * 1500)}px`
        captionContainer.style.marginTop = `${10 - (scrollPercentage * 1500)}px`
      });
    }
  }

  render() {
    const {
      data: {
        banner,
        mobilebanner,
        logo,
        logohover,
        heroBanner,
        mainhero,
        mainheromobile,
        mosaic,
        vcrop,
        vcropmobile,
      },
      pageContext: {
        locale,
        slug,
        content,
      },
      location
    } = this.props;

    const banners = {
      desktop: banner.edges[0].node.original.src,
      mobile: mobilebanner.edges[0].node.original.src,
      hero: heroBanner.edges[0].node.original.src,
    }

    return (
      <React.Fragment>
        <SEO
          pathname={location.pathname}
          locale={locale}
          banner={banners.desktop}
          slug={slug}
        />
        <Header
          slug={slug}
          locale={locale}
          location={location}
        />
        <Hero id="hero" className="mainhero desktop">
          <div id="david-jarre-mosaic" style={{
            backgroundImage: `url(${mainhero.publicURL})`,
            backgroundPosition: "left top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "0vh",
          }}>
            <div>
              <div>
                <h2 id="david-jarre">David Jarre</h2>
                <img id="mosaic" src={mosaic.publicURL} alt="David Jarre Mosaic" />
                <h3 id="caption">et vous, quel sera votre rêve ?</h3>
              </div>
            </div>
          </div>
        </Hero>

        <Hero className="mainhero mobile">
          <div style={{
            backgroundImage: `url(${mainheromobile.publicURL})`,
            backgroundPosition: "left top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "130%",
            backgroundPositionX: "center",
            backgroundPositionY: "0",
          }}>
            <div>
              <div>
                <h2>David Jarre</h2>
                <img src={mosaic.publicURL} alt="David Jarre Mosaic" />
                <h3>et vous, quel sera votre rêve ?</h3>
              </div>
            </div>
          </div>
        </Hero>
        
        <div id="top" className="vcrop desktop" style={{
          backgroundImage: `url(${vcrop.publicURL})`,
          height: 0,
          width: "100vw",
          top: 0,
          zIndex: -1,
          position: "fixed",
          mixBlendMode: "screen",
        }}></div>

        <div id="top" className="vcrop mobile" style={{
          backgroundImage: `url(${vcropmobile.publicURL})`,
          height: 0,
          width: "100vw",
          top: 0,
          zIndex: -1,
          position: "fixed",
          mixBlendMode: "screen",
        }}></div>

        <MainWrapper>
          <TourDates id="concerts">
            <h2>2024</h2>
            <div>
              {
                dates.length > 0 && dates.map((item, index) => {
                  return (
                    <div className="event" key={`event-${index}`}>
                      <div className="event-date">
                        {item.date}
                      </div>
                      <div className="event-location">
                        {item.location.city}<span>{item.location.place}</span>
                      </div>
                      <div className="event-link">
                        <a
                          href={item.location.order}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Réserver
                        </a>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </TourDates>

          <TourDates id="concerts">
            <h2>2025</h2>
            <div>
              {
                _dates.length > 0 && _dates.map((item, index) => {
                  return (
                    <div className="event" key={`event-${index}`}>
                      <div className="event-date">
                        {item.date}
                      </div>
                      <div className="event-location">
                        {item.location.city}<span>{item.location.place}</span>
                      </div>
                      <div className="event-link">
                        <a
                          href={item.location.order}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Réserver
                        </a>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </TourDates>
          
          <section className="content about" id="about">
            <div className="container">
              {/* <div className="headline" style={{ marginBottom: "3rem" }}>
                <p>"You wonder when he will arrive, and you wonder even more when he leaves..."</p>
                <span className="credits">Vanity Fair</span>
              </div> */}
              <YoutubeEmbed embedId="nnryeZGjdkU" />
            </div>
            <div className="container magicFromHome">
              <div className="row">
                <div className="pageIntro col nf np" style={{ marginTop: "5rem" }}>
                  {/* {
                    content.map((item, index_) => {
                      return <p key={`about-paragraph-${index_}`}>{item}</p>
                    })
                  } */}
                  <p>Un spectacle où magie, mentalisme et illusion s’entrelacent et se confondent. David fait partie de ces talents singuliers, imprévisibles, qu’on ne peut classer.</p>
                  <p>Mosaic, le premier one-man-show de l’artiste franco-britannique transporte le public dans un univers fascinant, là où les illusions défient toute logique laissant le spectateur bluffé et émerveillé à la fois grâce à son talent de magicien et à sa personnalité scénique. Il sait créer une atmosphère captivante où l’impossible devient possible. Dans une atmosphère intime et saisissante, David nous transporte, dans l’inconnu poétique où l’esprit humain est mis à l’épreuve et où la magie devient une réalité à part entière.</p>
                  <p>David est également connu pour ses apparitions à la télévision française, dans l'émission "Vivement Dimanche" animée par Michel Drucker.</p>
                </div>
              </div>
            </div>

            <Logo
              logo={logo.edges[0].node.publicURL}
              logohover={logohover.edges[0].node.publicURL}
            />

          </section>
          <Footer />
        </MainWrapper>
      </React.Fragment>
    )
  }
}

export default Index
export const pageQuery = graphql`
  query Index {
    heroBanner: allImageSharp(filter: {original: {src: {regex: "/IMG_9149/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    banner: allImageSharp(filter: {original: {src: {regex: "/david-jarre-header-desktop/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    mobilebanner: allImageSharp(filter: {original: {src: {regex: "/hero3/"}}}) {
      edges {
        node {
          original {
            src
          }
        }
      }
    }
    logo: allFile(filter: {relativePath: {eq: "david-jarre.com.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    logohover: allFile(filter: {relativePath: {eq: "vasarely.png"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    vasarely: allFile(filter: {relativePath: {eq: "vasarely.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    vasarelySmall: allFile(filter: {relativePath: {eq: "vasarely-small.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
    mainhero: file(base: {eq: "hero5.jpg"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [WEBP]
          layout: FULL_WIDTH
        )
      }
    }
    mainheromobile: file(base: {eq: "hero3.jpg"}) {
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [WEBP]
          layout: FULL_WIDTH
        )
      }
    }
    mosaic: file(base: {eq: "mosaic.png"}) {
      publicURL
    }
    vcrop: file(base: {eq: "Varaselli-crop-dark.jpg"}) {
      publicURL
    }
    vcropmobile: file(base: {eq: "Varaselli-crop-dark-double.jpg"}) {
      publicURL
    }
  }
`