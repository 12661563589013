import styled from '@emotion/styled'

export const Hero = styled.div`
  background-position-x: center;
  position: relative;
  height: 100vh;
  width: 100vw;
  > div {
    background-color: #010101;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: -1;
    
    > div {
      height: 100vh;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 40px;
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding: 300px 20px 20px 20px;
        align-items: center;
        justify-content: center;
      }
      h2 {
        font-size: 100px; 
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          font-size: 40px;
        }
        font-family: "Avenir";
        color: #D7D7D7;
        text-transform: uppercase;
        text-align: center;
        margin: 0;
      }
      h3 {
        font-family: "Avenir Light";
        color: #e7d492;
        text-align: center;
        text-transform: uppercase;
        font-size: 20px;
        margin: 100px 0 0;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          font-size: 14px;
          padding: 0;
          margin-top: 20px;
        }
      }
      img {
        width: 600px;
        display: block;
        margin: 0 auto;
        @media (max-width: ${props => props.theme.breakpoints.s}) {
          width: 250px;
        }
      }
    }
    
  }

`

export default Hero
