/* eslint-disable */
import React from 'react'
import styled from '@emotion/styled'

const LogoContainer = styled.div`
  padding-top: 100px;
  &.page {
    padding-top: 50px;
    padding-bottom: 60px;
  }
  img {
    display: block;
    margin: 0 auto;
    width: 100px;
  }
`;

export default class Logo extends React.Component {
  state = {
    hide: true,
    pendingMagic: false
  }

  undoThemagic = () => {
    if (!this.state.pendingMagic) {
      this.setState({ 
        hide: !this.state.hide,
        pendingMagic: true,
      })
    }
  }

  doThemagic = () => {
    this.setState({ pendingMagic: false })
  }

  render() {

    const { logohover, className } = this.props;

    return (
      <LogoContainer className={className}>
        <img src={logohover} alt="David Jarre Magic" />
      </LogoContainer>
    )
  }
}