import styled from '@emotion/styled'

export const TourDates = styled.section`
  color: #1b1b1b;
  display: flex;
  transition: all .4s ease-out;
  mix-blend-mode: "overlay";
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    display: block;
  }

  .loading {
    opacity: 0;
  }
  .loaded {
    opacity: 1;
  }

  h2 {
    padding: 1.875rem;
    text-align: right;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-transform: uppercase;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      transform: none;
      writing-mode: initial;
      font-family: "Avenir";
      margin: 0;
      font-size: 3rem;
      line-height: 2.8rem;
      padding: 1.8rem 1rem 1rem 1rem;
    }
  }

  > div {
    border-left: .125rem solid #000;
    flex: 1;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      border-left: none;
      border-top: 1px solid #000;
    }

    > div {
      padding: 1.875rem;
      display: flex;
      border-bottom: 2px solid #1b1b1b;
      text-transform: uppercase;
      align-items: center;
      ${'' /* &:last-of-type {
        border-bottom: none;
      } */}
      &.handle {
        font-family: "Avenir";
        font-size: 3rem;
        line-height: 2.8rem;
        padding: 1.875rem 1.875rem 1.2rem 1.875rem;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all .3s ease-out;

        &:hover {
          background: #1B1B1B;
          color: #be8953;
        }
      }
      @media (max-width: ${props => props.theme.breakpoints.s}) {
        padding: .625rem;
        border-bottom: 1px solid #000;
      }
      > div {
        flex: .5;
        
        &.event-date {
          font-size: 2.25rem;
          font-weight: 700;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            font-size: 16px;
          }
        }

        &.event-location {
          flex: 1;
          font-size: 2rem;
          font-family: "Avenir", sans-serif;
          font-weight: 500;
          @media (max-width: ${props => props.theme.breakpoints.s}) {
            font-size: 1rem;
          }

          span {
            display: block;
            font-size: 1rem;
            font-family: "Slate", sans-serif;
            font-weight: 300;
            @media (max-width: ${props => props.theme.breakpoints.s}) {
              font-size: .8375rem;
            }
          }
        }

        &.event-link {
          a, span {
            display: inline-block;
            text-decoration: none;
            font-size: 1.2rem;
            font-family: Avenir, sans-serif;
            color: #1B1B1B;
            background-color: #fff;
            padding: .5rem 1.5625rem .3125rem;
            border: 2px solid #1b1b1b;
            
            @media (max-width: ${props => props.theme.breakpoints.s}) {
              font-size: 1.275rem;
              padding: .3125rem;
            }
          }

          a {
            &:hover {
              background: #1B1B1B;
              color: #D7D7D7;
            }
          }
          span {
            opacity: .3;
            cursor: default;
          }
        }
      }

    }
  }
`

export default TourDates
